<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-row class="w-75">
          <b-col
            cols="12"
            md="12"
          >
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Teknisyen adı.."
                @keydown.enter="searchData"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchData"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          :to="'/service/work-orders/defines/workers/add'"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
        small
      >
        <template #cell(location)="data">
          {{ data.item.location }}
          <div>
            <small class="text-warning">{{ data.item.service_sections_title }}</small>
          </div>
        </template>
        <template #cell(username)="data">
          {{ data.item.username }}
          <div>
            <small class="text-secondary">
              {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
            </small>
          </div>
        </template>
        <template #cell(control)="data">
          <div>
            <b-dropdown
              variant="link"
            >
              <template #button-content>
                <feather-icon
                  icon="MenuIcon"
                  size="16"
                  class="text-body"
                />
              </template>
              <b-dropdown-item :to="'/service/work-orders/defines/workers/edit/' + data.item.id">
                Güncelle
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="removeData(data.item.id)">
                Sil
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BCard, BCardFooter, BCardHeader, BDropdown, BDropdownDivider, BDropdownItem, BPagination, BTable, BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BButton,
    BPagination,
    BCardFooter,
    BTable,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'fullname',
          label: 'Adı Soyadı',
        },
        {
          key: 'location',
          label: 'Lokasyon',
        },
        {
          key: 'username',
          label: 'Ekleyen',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '120px' },
        },
      ],
      dataQuery: {
        select: [
          'com_service_workers.id       AS id',
          'com_service_workers.fullname AS fullname',
          'com_location.title           AS location',
          'com_service_sections.title   AS service_sections_title',
          'com_user.name                AS username',
          'com_service_workers.created  AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['swoWorkers/dataList']
    },
    dataCount() {
      return this.$store.getters['swoWorkers/dataCounts']
    },
    saveData() {
      return this.$store.getters['swoWorkers/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveData(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      const like = {}
      if (this.search) {
        like['com_service_workers.fullname'] = this.search
        this.dataQuery.like = like
      } else if (this.dataQuery.like) {
        delete this.dataQuery.like
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('swoWorkers/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('swoWorkers/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
